<template>
  <div id="usluge">
    <div id=image-holder2>
      <h1>Usluge</h1>
    </div>
    <section id="usluga1" >
      <div class="naslov"><h2>Konsultacije prilikom pokretanja poslovanja</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>Izbor forme i šifre delatnosti</li>
            <li>Troškovi budućeg poslovanja</li>
            <li>Prava i obaveze</li>
          </ul>
          <p>
            Analiziramo, savetujemo i profesionalno pomažemo u konačnom izboru određene forme delatnosti koja umnogome može olakšati uštedu novca i vremena.
          </p>
        </div>
        <div class="section-image">
          <img src="@/assets/konsultacija.jpg" width="200" height="200" alt="konsultacija"/>
        </div>
      </div>
    </section>
    <div id="usluga2" ></div>
    <section>
      <div  class="naslov"><h2>Računovodstvene i knjigovodstvene usluge</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>Finansijsko knjigovodstvo</li>
            <ul>
              <li>vođenje glavne knjige u skladu sa važećim propisima u Republici Srbiji</li>
              <li>sastavljanje svih računovodstvenih iskaza</li>
              <li>vođenje knjige ulaznih i knjige izlaznih faktura</li>
              <li>izrada kalkulacija za veleprodaju, maloprodaju, uvoz, izvoz</li>
              <li>vođenje dinarske i devizne blagajne</li>
              <li>vođenje knjige evidencije prometa i usluga - KPO</li>
            </ul>
            <li>KEP knjiga</li>
            <li>Robno-materijalno knjigovodstvo</li>
            <ul>
              <li>Ulaz-izlaz materijala, evidencija zaliha gotovih proizvoda, sitnog inventara, rezervnih delova</li>
              <li>obračun proizvodnje za proizvodne firme</li>
              <li>vođenje osnovnih sredstava i obračun amortizacije </li>
            </ul>
            <li>Devizno poslovanje</li>
          </ul>
        </div>
        <div class="section-image">
          <img src="@/assets/kalkulator.jpg" width="200" height="200" alt="slika-knjigovodstvo"/>
        </div>
      </div>
    </section>
    <section>
      <div id="usluga3" class="naslov"><h2>Prelazak sa prostog na dvojno knjigovodstvo</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>Za određene grupe preduzetnika, od 1.1.2019. nadležni organ je uveo obavezu vođenja dvojnog knjigovodstva</li>
            <li>Ovom odlukom, nadležni organ podiže nivo transparentnosti i tačnosti u poslovanju preduzetnika</li>
          </ul>
          <p>
            Ako se nalazite u navedenoj grupi preduzetnika, naš savet je da ozbiljno razmislite o izboru poreskog režima. Jer jer porast obima obaveza i posla, koji nastaju kada preduzetnik mora da vodi dvojno knjigovodstvo dovodi do povećanja troškova usluga administracije i knjigovodstva. 
            Postoji i druga opcija, koju može da razmotri veliki broj preduzetnika koji za nju ispunjava uslove – povratak u paušalni sistem oporezivanja.
            Uslov da preduzetnici knjigaši realizuju ovu opciju, jeste obim prihoda manji od 6 miliona dinara na godišnjem nivou. Vremenski rok za izjašnjavanje i pokretanje ove opcije je do 30. novembra ove godine.
          </p>
        </div>
        <div class="section-image">
          <img src="@/assets/fascikla.jpg" style="opacity:0.9" width="200" height="200" alt="slika-fascikla"/>
        </div> 
      </div>
    </section>
    <section id="usluga4">
    <div  class="naslov"><h2>Obračun zarada i kadrovska evidencija</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>Zarade</li>
            <li>Evidencija zaposlenih</li>
            <li>Zdravstveno i socijalno osiguranje</li>
          </ul>
          <ul>
            <li>Obračun zarada sa pripadajućim poreskim prijavama, virmanima i elektronskim slanjem</li>
            <li>Obračun ugovora o delu, autorskih ugovora, zakupa i sl. sa por. prijavama, virmanima i elektronskim slanjem</li>
            <li>Izrada svih obrazaca o prijemu i prestanku radnog odnosa zaposlenih sa elektronskom prijavom/odjavom)</li>
          </ul>
        </div>
        <div class="section-image">
          <img src="@/assets/zarade.jpg" width="200" height="200" alt="slika-potpisivanje"/>
        </div>        
      </div>
    </section>
    <section>
    <div id="usluga5" class="naslov"><h2>PDV i ostali obračuni poreza</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>KIR</li>
            <li>KPR</li>
            <li>POPDV</li>
          </ul>
          <ul>
            <li>Savetovanje u vezi poreza</li>
            <li>Vođenje PDV evidencija, obračun PDV-a, izrada poreskih prijava</li>
            <li>Obračun ostalih poreskih obaveza i vođenje poreskih evidencija</li>
            <li>Periodična kontrola proknjiženih obaveza na računima kod Poreske uprave</li>
          </ul>
        </div>
        
        <div class="section-image">
          <img src="@/assets/porez.jpg" style="opacity:0.9" width="200" height="200" alt="slika-porez"/>
        </div> 
      </div>
    </section>
    <section>
    <div id="usluga6" class="naslov"><h2>Finansijski izveštaji</h2></div>
      <div class="section-content">
        <div class="section-text">
          <ul>
            <li>Bilansi</li>
            <li>Izveštaji uz završni račun za poslovnu godinu</li>
          </ul>
          <ul>
            <li>Izrada potrebnih bilansa i dokumenata za kredite</li>
            <li>Priprema izveštaja o poslovanju i izrada završnog računa sa elektronskim slanjem</li>
            <li>Analiza poslovanja sa izradom bilansa i obračunom rezultata po zahtevu klijenta</li>
          </ul>
        </div>
        <div class="section-image">
          <img src="@/assets/izvestaj.jpg" style="opacity:0.9" width="200" height="200" alt="slika-izvestaj"/>
        </div> 
      </div>
    </section>
<!--     
    <section>
    <div id="cenovnik" class="naslov"><h2>Cenovnik</h2></div>
      
      <button class="dugme" @click="otvoriPDF()">
        Prikaži cenovnik usluga
      </button>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'Usluge',
  components: {

  },
  data: function(){
    return(
      {
        
      }
    );
  },
  methods:{
    otvoriPDF() {
      window.open(process.env.VUE_APP_API_URL+"cenovnik.pdf", '_blank');
    }
  },
  mounted(){
    
  }
}
</script>

<style scoped>


section{
  background-color: #dcdcdc;  
}

.section-content{
  padding:30px;
  padding-top:40px;
  padding-bottom: 40px;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section-text{
  display:flex;
  flex-direction: column;
  width:60%;
  
  justify-content: center;
  align-items: flex-start;
}

.section-image{
  margin:20px;
  /* margin-top:-10px; */
  margin-left: 40px;
  width:30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-image img{
  width:280px;
  height: 280px;
  border-radius: 25px;
}

@media screen and (max-width: 800px) {
  .section-image{
    width:100%;
    margin:0;
  }
  .section-image img{
    width:220px;
    height: 220px;
    border-radius: 25px;
  }
  .section-text{
    width: 100%;
  }
  button{
    /* font-size:20px; */
    height:60px;
    line-height: 1.5rem;
  }
}

section ul{
  text-align: left;
  font-size: 20px;
}

section li{
  margin:2px; 
}

section p{
  padding:15px;
  font-size: 20px;
  text-align: left;
}

.cenovnik-content{
  padding:30px;
  padding-top:40px;
  padding-bottom: 40px;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

table{
  width:600px;
  text-align: left;
  background-color: white;  
  border-radius: 15px;
}

.table-naslov{
  text-align: center;
}

</style>